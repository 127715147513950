// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import uk from '@fullcalendar/core/locales/uk'
import ru from '@fullcalendar/core/locales/ru'
import de from '@fullcalendar/core/locales/de'
import en from '@fullcalendar/core/locales/en-gb'
import i18n from '@/libs/i18n'
import router from '@/router'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted, onUnmounted, onBeforeMount } from '@vue/composition-api'
import store from '@/store'

import { useEventsUI } from '@/views/apps/service/proposal/useProposals'
import { useRouter } from '@core/utils/utils'
import {useUserUi} from '@/views/apps/user/useUser'
import { translateApiErrors } from '@/mixins/functions'

export default function userCalendar() {
  // Use toast
  const toast = useToast()
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null

  const setEventOrganizer = (user)=>{
    store.commit('calendar/setOrganizer', user)
  }

  const setEventData = (data)=>{
    store.commit('calendar/setData', data)
  }

  const cleanEventData = ()=>{
    setEventOrganizer({})
    setEventData({})
  }
  const updateAuthHeader = ()=>{

    //Do not update auth headers for managed user!
    if(store.getters['user/userManaged'].id){
      return
    }
    const {setAuthHeaderManagedUser,deleteAuthHeaderManagedUser}=useUserUi()

    const eventOrganizer = store.getters['calendar/eventOrganizer']

    if(eventOrganizer && eventOrganizer.managed && eventOrganizer.id){
      setAuthHeaderManagedUser(eventOrganizer.id)
    } else {
      deleteAuthHeaderManagedUser()
    }
  }

  onBeforeMount(() => {
    updateAuthHeader()
  })
  onMounted(()=>{
    calendarApi = refCalendar.value.getApi()
  })
  onUnmounted(() => {
    cleanEventData()
    updateAuthHeader()
  })
  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    Service: 'info',
    Private: 'secondary',
  }

  const calendarLocale = locale => {
    switch (locale) {
      case 'uk':
        return uk
      case 'ru':
        return ru
      case 'de':
        return de
      default:
        return en
    }
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    title: '',
    start: '',
    end: '',
    allDay: false,
    extendedProps: {
      calendar: '',
      currency: null,
      participants: [],
      description: null,
      organizer_id: null,
      subject_id: null,
      appointment: null,
      min_price: null,
      post_status: 'published',
      accept_date: null,
      submit_date: null,
      decline_date: null,
      decline_reason: null,
      withdraw_date: null,
      withdraw_reason: null,
    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------
  const addEventInCalendar = eventData => {
    toast({
      component: ToastificationContent,
      position: 'bottom-right',
      props: {
        title: i18n.t('Event Added'),
        icon: 'CheckIcon',
        variant: 'success',
      },
    })
    calendarApi.addEvent(eventData)
  }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {
    toast({
      component: ToastificationContent,
      props: {
        title: i18n.t('Event Updated'),
        icon: 'CheckIcon',
        variant: 'success',
      },
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)

    if (!existingEvent) return

    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus

    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, { allDay: updatedEventData.allDay })

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index]
      existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
    }
  }

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      props: {
        title: i18n.t('Event Removed'),
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
    calendarApi.getEventById(eventId).remove()
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: {
        calendar,
        currency,
        participants,
        description,
        appointment,
        organizer_id,
        subject_id,
        subject,
        organizer,
        post_status,
        min_price,
        accept_date,
        submit_date,
        cancel_date,
        cancel_reason,
        cancel_by,
        decline_date,
        decline_reason,
        complete_date,
        withdraw_date,
        withdraw_reason,
        request,
      },
      allDay,
    } = eventApi

    return {
      id,
      title,
      start,
      end,
      extendedProps: {
        calendar,
        currency,
        participants,
        description,
        appointment,
        organizer_id,
        subject_id,
        subject,
        organizer,
        post_status,
        min_price,
        accept_date,
        submit_date,
        cancel_date,
        cancel_reason,
        cancel_by,
        decline_date,
        decline_reason,
        complete_date,
        withdraw_date,
        withdraw_reason,
        request,
      },
      allDay,
    }
  }
  const {
    eventWithdrawn,
    eventDeclined,
    eventAccepted,
    currentUserIsEventOrganizer,
    eventCanBeUpdated,
    eventCanBeWithdrawn,
    eventCanBeDeclined,
    eventCanBeAccepted,
  } = useEventsUI()
  const serviceCalendar = e => e.extendedProps.calendar === 'Service'

  const eventStatus = e => {
    if (eventWithdrawn(e)) return 'withdrawn'
    if (eventDeclined(e)) return 'declined'
    if (eventAccepted(e)) return 'accepted'

    return 'under-review'
  }

  const eventType = e => {
    const eventOrganizer = currentUserIsEventOrganizer(e)
    const serviceEvent = serviceCalendar(e)
    if (eventOrganizer && serviceEvent) return 'offer'
    if (!eventOrganizer && serviceEvent) return 'request'
    return ''
  }
  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------

  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  const handleErrors = errors => {
    translateApiErrors(errors)
    Object.values(errors).shift().forEach(error=>{
      toast({
        component: ToastificationContent,
        position: 'bottom-right',
        props: {
          title: error,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }

  const fetchUserEvents = (user) => {
    setEventOrganizer(user)
    updateAuthHeader()
    refetchEvents()
  }

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const addEvent = eventData => {
    submittingFetchEvents.value = true

    store.dispatch('calendar/addEvent', { data: eventData }).then(response => {
      const addedEvent = response.data.data
      addedEvent.classNames = eventStatus(addedEvent).concat(' ', eventType(addedEvent))
      cleanEventData()
      refetchEvents()
      toast({
        component: ToastificationContent,
        position: 'bottom-right',
        props: {
          title: i18n.t('Event Added'),
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    }).catch(error => {
      const { errors } = error.response.data
      handleErrors(errors)
    }).finally(()=>{
      submittingFetchEvents.value = false
    })
  }

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = data => {
    const eventData = JSON.parse(JSON.stringify(data))
    eventData.extendedProps.appointment = eventData.start
    submittingFetchEvents.value = true

    store.dispatch('calendar/updateEvent', { data: eventData })
      .then(response => {
      const updatedEvent = response.data.data
      const propsToUpdate = ['id', 'title']
      const extendedPropsToUpdate = ['calendar', 'currency', 'participants', 'description', 'appointment', 'post_status', 'min_price']

      updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
    }).catch(error => {
      const { errors } = error.response.data
      handleErrors(errors)
    }).finally(()=>{
      submittingFetchEvents.value = false
    })
  }

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = () => {
    submittingFetchEvents.value = true

    const eventId = event.value.id
    store.dispatch('calendar/removeEvent', { id: eventId })
      .then(response => {
      if ([200, 201, 'success'].includes(response.status)) {
        removeEventInCalendar(eventId)
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('Error removing calendar event'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }).catch(error => {
      const { errors } = error.response.data
      handleErrors(errors)
    }).finally(()=>{
      submittingFetchEvents.value = false
    })
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(() => store.state.calendar.selectedCalendars)

  watch(selectedCalendars, () => {
    refetchEvents()
  })

  // Route Params
  const { route } = useRouter()
  const routeParams = computed(() => route.value.params)
  watch(routeParams, () => {
    refetchEvents()
  })
  const isEventHandlerSidebarActive = ref(false)

  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  const submittingFetchEvents = ref(false)

  const fetchEvents = (info, successCallback) => {
    // If there's no info => Don't make useless API call
    if (!info) return

    let eventForEdit = false
    submittingFetchEvents.value = true



    if (router.currentRoute.name === 'event-calendar-edit') {
      eventForEdit = parseInt(router.currentRoute.params.id, 10)
      store.dispatch('calendar/fetchEvent', {
        eventId: eventForEdit,
      }).then(response => {
        const e = response.data
        e.classNames = eventStatus(e).concat(' ', eventType(e))
        if (eventForEdit === e.id) {
          eventForEdit = e
        }
        successCallback(response.data)
      }).then(() => {
        event.value = grabEventDataFromEventApi(eventForEdit)
        event.value.extendedProps.subject = eventForEdit.subject
        isEventHandlerSidebarActive.value = true
      })
    } else {
      // Fetch Events from API endpoint
      store
        .dispatch('calendar/fetchEvents', {
          calendars: selectedCalendars.value.join(','),
          include: ['organizer.languages'].join(','),
          start_date: info.startStr,
          end_date: info.endStr,
          per_page: 300,

        })
        .then(response => {
          response.data.forEach(e => {
            e.classNames = eventStatus(e).concat(' ', eventType(e))
            if (eventForEdit === e.id) {
              eventForEdit = e
            }
          })
          successCallback(response.data)
        })
        .then(() => {
          submittingFetchEvents.value = false
          if (eventForEdit) {
            event.value = grabEventDataFromEventApi(eventForEdit)
            event.value.extendedProps.subject = eventForEdit.subject
            isEventHandlerSidebarActive.value = true
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('Error fetching calendar events'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------

  const getEventTitle = eventId => {
    const point = store.getters['calendar/eventData']
    if (point.id === eventId) {
      return point.name
    }
    return ''
  }

  const getStartTime = ()=>{
    const date = new Date()
    return `${date.getHours()}:${date.getMinutes()}`
  }

  const scrollTo = ()=>{
    const currentTimeLine = document.querySelector('.fc-timegrid-now-indicator-line');

    if(currentTimeLine){
      return  currentTimeLine?.scrollIntoView({ block: 'end',  behavior: 'smooth' });
    }

    return window.scrollTo({
      top: document.querySelector('.fc-timegrid-bg-harness').offsetHeight,
      behavior: "smooth",
    });
  }


  const calendarOptions = ref({
    height: 'auto',
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: 'dayGridMonth,timeGridDay,listMonth',
    },
    nowIndicator: true,
    scrollTime :  getStartTime(),
    businessHours: {
      daysOfWeek: [ 1,2,3,4,5,6,7 ],
      startTime: '08:00',
      endTime: '23:00',
    },
    events: fetchEvents,
    /*
        Limits which dates the user can navigate to and where events can go.
        ? Docs: https://fullcalendar.io/docs/v4/validRange
      */
    validRange(nowDate) {
      return {
        start: '2023-01-01',
      }
    },
    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: true,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 8,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    locale: (calendarLocale(i18n.locale)),

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

      return [
        // Background Color
        `bg-light-${colorName}`,
      ]
    },
    eventClick({ event: clickedEvent }) {
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      event.value = grabEventDataFromEventApi(clickedEvent)

      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
    },
    navLinkDayClick: function(date, jsEvent) {
      if(calendarApi.view.type!=='timeGridDay'){
        calendarApi.changeView('timeGridDay',new Date(date))
        scrollTo()
      }
    },
    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date })))
      // eslint-disable-next-line no-use-before-define

      if(calendarApi.view.type!=='timeGridDay'){
        calendarApi.changeView('timeGridDay',new Date(info.date))
        return scrollTo()
      }

      event.value.start= new Date(info.date).getTime()

      isEventHandlerSidebarActive.value = true

      const point = store.getters['calendar/eventData']
      if (router.currentRoute.name === 'event-calendar-add' && point.id) {
        // Set required Service Calendar properties
        const eventId = point.id
        event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { title: getEventTitle(eventId) })))
        event.value = JSON.parse(JSON.stringify({
          ...event.value,
          extendedProps: {
            ...event.value.extendedProps,
            subject_id: eventId,
            calendar: 'Service',
            organizer_id: store.getters['calendar/eventOrganizer'].id,
          },
        }))
      } else {

        // Set required Private Calendar properties

        event.value = JSON.parse(JSON.stringify({
          ...event.value,
          extendedProps: {
            ...event.value.extendedProps,
            calendar: 'Private',
            organizer_id: store.getters['account/user'].id,
          },
        }))
      }
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent))
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isCalendarOverlaySidebarActive = ref(false)

  return {
    refCalendar,
    calendarLocale,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    eventCanBeUpdated,
    eventCanBeWithdrawn,
    eventCanBeDeclined,
    eventCanBeAccepted,
    refetchEvents,
    fetchUserEvents,
    fetchEvents,
    submittingFetchEvents,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
  }
}
